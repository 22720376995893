import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataDialogProps } from '../../../../models/data.dialog.props';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { SkeletonComponent } from '../../../../components/SkeletonComponet';
import { alertActions } from '../../../../auth/actions/alert.actions';
import { TypeDialog } from '../../../../helpers/type_dialog';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { languages } from '../../../../helpers/consts';
import { AppUser } from '../models/app-user';
import { appUserActions } from '../actions/app-user.actions';
import { Role } from '../../../settings/roles/models/role';
import { User } from '../../../settings/users/models/user';
import { customerActions } from '../../../customer/actions/customer.actions';
import DialogSelectCustomer from '../../../customer/components/dialog.select-customer';
import { Customer } from '../../../customer/models/customer';

  function DialogAppUser({t}: WithTranslation) {
    const dispatch = useDispatch();    
    const openAppUser  = useSelector((state: any) => state.appUsers.openAppUser as DataDialogProps<AppUser>);
    const user  = useSelector((state: any) => state.authentication.user as User);
    const [inputs, setInputs] = React.useState<AppUser>(openAppUser?.select);
    const [submitted, setSubmitted] = React.useState(false);
    // const roles = [new Role({ roleId:1, name: "Usuario" }), new Role({roleId:2,name: "Instructor"})]


    useEffect(() => {
        setInputs(openAppUser?.select)
        setSubmitted(false)
    }, [openAppUser]);

    const handleClose = () => {
      setInputs(new AppUser())
      dispatch(appUserActions.openModalUser({
        open : false,
        select : new AppUser(),
        type : 0,
        loading: false
      }));
      setSubmitted(false)
    };

    const searchCustomer = () =>{
        dispatch(customerActions.openModalSearchCustomer({
            open : true,
            select :[],
            type : TypeDialog.add,
            loading: true
        }));
    }

    const onSelectCustomer = (customer: Customer)=>{
        setInputs({
            ...inputs,
            name: customer.name,
            email:customer.email,
            customerId: customer.customerId,
            lang: user.lang,
        })
    }
    

    
    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if (inputs.name && inputs.email) {
          if(openAppUser?.type ===0 && inputs.password){
            dispatch(appUserActions.add(inputs));
          }
          if(openAppUser?.type ===1){
            dispatch(appUserActions.edit(inputs));
          }
      }
    }

    function handleChange(e:any) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }
    function handleChangeCheck(e:any) {
        const { name, checked } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: checked }));
    }
    const footer = (
        <div>
            <Button onClick={handleSubmit} label={openAppUser?.type ===0 ? t("add"): t("edit")} icon="pi pi-check"/>
        </div>
    );

   
    return (
        <Dialog header={openAppUser?.type ===0 ?t("add"): t("edit")} position="top" footer={footer} visible={openAppUser?.open??false} onHide={handleClose} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '45vw'}}>
            <div>
                {openAppUser.loading ? <SkeletonComponent/> :
               !inputs.customerId? 
                <div>
                    <h4>{t("pleaseSelectACustomer")}</h4>
                    <Button onClick={searchCustomer} icon="pi pi-search-plus">{t("selectACustomer")}</Button> 
                </div>
               : <div className="p-fluid grid mt-3">

                    <div className="field col-6 md:col-6">
                        <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-user" />
                            <InputText id="name" name="name" value={inputs.name} onChange={handleChange}  className={(!inputs?.name) ? "block p-invalid":"block"}  />
                            <label htmlFor="name">{t("name")}</label>
                        </span>
                        {(!inputs?.name) ?<small id="name-help" className="block p-error">{t("nameRequired")}</small>: <></> }
                    </div>
                    <div className="field col-6 md:col-6">
                            <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-id-card" />
                                <InputText id="email" name="email" value={inputs.email}
                                disabled={openAppUser.type == TypeDialog.edit}
                                    onChange={handleChange} className={(!inputs?.email) ? "block p-invalid":"block"}  />
                                <label htmlFor="email">{t("email")}</label>
                            </span>
                            {(!inputs?.email) ?<small id="email-help" className="block p-error">{t("emailRequired")}</small>: <></> }
                    </div>
                  
                    {/* <div className="field col-6 md:col-4">
                        <span className="p-float-label">
                            <Dropdown inputId="dropdownRol" name="roleId" value={inputs.roleId} options={roles}
                             onChange={handleChange} optionLabel="name" optionValue="roleId"
                            className={(!inputs?.roleId) ? "p-invalid":""} />
                            <label htmlFor="dropdownRol">{t("role")}</label>
                        </span>
                        {(!inputs?.roleId) ?<small id="roleId-help" className="block p-error">{t("roleRequired")}</small>: <></> }
                    </div>  */}


                    {
                     openAppUser.type == TypeDialog.add ?
                     <div className="field col-6 md:col-6">
                        <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-user" />
                            <InputText id="password" name="password"  value={inputs.password} onChange={handleChange} className={(!inputs?.password ) ? "block p-invalid":"block"}  />
                            <label htmlFor="password">{t("password")}</label>
                        </span>
                        {(!inputs?.password ) ?<small id="password-help" className="block p-error">{t("passwordRequired")}</small>: <></> }
                    </div>
                        :<></>
                    }
                     
                    <div className="field col-6 md:col-6">
                        <span className="p-float-label">
                            <Dropdown inputId="dropdownLang" name="lang" value={inputs.lang} options={languages}
                             onChange={handleChange} optionLabel="name" optionValue="lang" 
                            className={(!inputs?.lang) ? "p-invalid":""}/>
                            <label htmlFor="dropdownLang">{t("language")}</label>
                        </span>
                        {(!inputs?.lang) ?<small id="documentLang-help" className="block p-error">{t("languageRequired")}</small>: <></> }
                    </div> 
                  
                
                    {
                        openAppUser.type == 1?
                        <div className="field col-6 md:col-6">
                            <span className="p-float-label p-input-icon-left">
                                <Checkbox inputId="cbActive" name="active" value={t("active")} onChange={handleChangeCheck} checked={inputs.active??false}></Checkbox>
                                <label htmlFor="cbActive" className="p-checkbox-label">{t("active")}</label>
                            </span>
                        </div>
                        : <div></div>
                    }
              


                </div>
                }
            <DialogSelectCustomer onSelect={onSelectCustomer}/>
            </div>
        </Dialog>
    );
  }
    
  export default withTranslation()(DialogAppUser);