const dev = {
  //apiUri: "http://192.168.100.136:8080/api",
  apiUri: "https://apigym.planetcolorhn.com/api",
   // apiUri: "http://192.168.0.153:8081/api",
   isProduction: false,
   enabledNewFingerPrint: true
  };
  
  const prod = {
    apiUri: "https://apigym.planetcolorhn.com/api",
    isProduction: true,
  };
  
  const environment = process.env.NODE_ENV === 'production'
    ? prod
    : dev;
  
  export default {
    ...environment
  };
