export class AppUser {
    userId?: number;
    name?: string;
    password?: string;
    customerId?:string;
    customer?: string;
    email?: string;
    phone?: string;
    company?: string;
    companyId?: string;
    logoUri?: string;
    active?: boolean;
    createdBy?: number;  
    createdDate?: Date;
    lang?:string 



    constructor(datos?: AppUser) {
        if (datos != null) {
        this.userId = datos.userId;
        this.name = datos.name;
        this.password = datos.password;
        this.customer = datos.customer;
        this.customerId = datos.customerId;
        this.email = datos.email;
        this.phone = datos.phone;
        this.active = datos.active;
        this.logoUri = datos.logoUri;
        this.company = datos.company;
        this.companyId = datos.companyId;
        this.lang = datos.lang;
        this.createdBy = datos.createdBy;
        this.createdDate = datos.createdDate;
        return;
        }
    }
}
