import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useEffect } from 'react';
import { Button } from 'primereact/button';
import { SkeletonComponent } from '../../../components/SkeletonComponet';
import { alertActions } from '../../../auth/actions/alert.actions';
import { TemplatePaginator } from '../../../components/TemplatePaginator';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AppUser } from './models/app-user';
import DialogAppUser from './components/dialog.app-user';
import { appUserActions } from './actions/app-user.actions';
import { User } from '../../settings/users/models/user';
import { DialogAppRestartPassword } from './components/dialog.app-restart-password';

function ListAppUserPage({t}: WithTranslation) {
    const appUsers: AppUser[] = useSelector((state:any) => state.appUsers.appUsers as AppUser[]);
    const loading: boolean= useSelector((state:any) => state.appUsers.loading);
    const user  = useSelector((state: any) => state.authentication.user as User);
    const dispatch = useDispatch();

    useEffect(() => { 
        dispatch(appUserActions.getUsers());
        dispatch(appUserActions.openModalRestart({
            open : false,
            select : new AppUser(),
            type : 0,
            loading: false
        }));
    },[]);

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <h5 className="m-0">{t("appUsersList")}</h5>
                <span className="p-input-icon-left">
                    <Button label={t("addNew")} onClick={handAdd} icon="pi pi-pencil" iconPos="left"  />
                </span>
            </div>
        )
    }
    const  handAdd = () =>{
         dispatch(appUserActions.openModalUser({
                open : true,
                select : new AppUser(),
                type : 0,
                loading: false
            }));
    }
    const header = renderHeader();

    const actionBodyTemplate = (rowData:AppUser) => {
        const showDialog =()=>{
            dispatch(appUserActions.openModalUser({
                open : true,
                select : rowData,
                type : 1,
                loading: false
            }));
        }
        const showDialogRestart =()=>{
            var permissions = user.permissions.findIndex(x=> x.path == "btn_restart_password");
            if(permissions== -1) {
                dispatch(alertActions.error(t("noAccessToActionRequest")));
                return;
            }
            dispatch(appUserActions.openModalRestart({
                open : true,
                select : rowData,
                type : 0,
                loading: false
            }));
        }
        return  <span className="p-buttonset">
                    <Button label={t("edit")} icon="pi pi-pencil" iconPos="left" onClick={showDialog} />
                    {/* <Button label="Restablecer" className="p-button-success" icon="pi pi-info-circle" iconPos="left" onClick={showDialogRestart} /> */}
                </span>
    }
    return ( 
        <div className="grid mt-1">
            <div className="col-12 card">
                {loading ? <SkeletonComponent/> :
                <DataTable  emptyMessage={t("noResultFound")} value={appUsers} paginatorTemplate={TemplatePaginator as any} 
                        responsiveLayout="scroll"  rows={10}  size="small" stripedRows   header={header} >
                        <Column field="appUserId" header={t("id")}></Column>
                        <Column field="name" header={t("name")}></Column>
                        <Column field="email" header={t("email")}></Column>
                        {/* <Column field="role" header={t("role")}></Column> */}
                        <Column header={t("action")} body={actionBodyTemplate} />
                    </DataTable>
                }
                <DialogAppUser></DialogAppUser>
                <DialogAppRestartPassword ></DialogAppRestartPassword>
            </div>
        </div>
    ); 
}

export default withTranslation()(ListAppUserPage);